@charset "UTF-8";

// メディアクエリー（ブレークポイント別管理）
// -----------------------------------------------------------
//    below は引数のブレークポイント以下、above は引数のブレークポイントより上のサイズへの
//    メディアクエリー別スタイルが記述される
//    引数には原則、_vars.scss で定義したブレークポイント用変数を指定する
//
//    使用例：
//    div {
//        @include below($breakMedium) {
//            margin-bottom: 0;
//        }
//        @include inbetween($breakSmall, $breakMedium) {
//            margin-bottom: 40px;
//        }
//    }
// -----------------------------------------------------------
@mixin below($breakpoint) {
    @media only screen and (max-width: $breakpoint) {
        @content;
    }
}
@mixin above($breakpoint) {
    @media screen and (min-width: $breakpoint + 1) {
        @content;
    }
}
@mixin inbetween($breakpointSmall, $breakpointLarge) {
    @media screen and (min-width: $breakpointSmall + 1) and (max-width: $breakpointLarge) {
        @content;
    }
}
