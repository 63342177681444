@charset "UTF-8";

body#app {
  .none {
    @include below($md) {
      display: none;
    }
  }
  .none-02 {
    display: none;
    @include below($sm) {
      display: block;
    }
  }
}