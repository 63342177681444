@charset "UTF-8";

.books-list {
  margin-top: 46px;
  margin-bottom: 20px;
  font-size: 1.4rem;
  @include below($sm) {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  >p {
    margin-bottom: 30px;
    @include below($sm) {
      margin-bottom: 20px;
    }
  }
}