@charset "UTF-8";

.listenapp {
  .function-list {
    list-style: none;
    counter-reset: number;
    padding: 0;
    margin-top: 18px;
    li {
      font-size: 1.4rem;
      line-height: normal;
      margin-bottom: 0.4em;
      .number {
        counter-increment: number;
        content: counter(number);
        background: #fff;
        border: 1px solid $listenappColor;
        display: inline-block;
        width: 20px;
        height: 20px;
        text-align: center;
        border-radius: 50%;
        color: $listenappColor;
        margin-right: 6px;
        font-weight: 900;
        font-size: 13px;
        &.-for {
          margin-right: 2px;
        }
      }
      >.title {
        font-size: 1.6rem;
        font-weight: 900;
        display: inline-block;
      }
      >.description {
        padding-left: 18px;
        margin-top: 12px;
        margin-bottom: 14px;
      }
    }
  }
}
