@charset "UTF-8";

.how-to-hdg {
  text-align: center;
  margin-bottom: 50px;
  line-height: 1;
  >span {
    font-size: 2.8rem;
    text-align: center;
    font-weight: 700;
    position: relative;
    @include below($sm) {
      font-size: 2.5rem;
    }

    &:before, &:after {
      content: "";
      position: absolute;
      display: inline-block;
      top: 6px;
      background-color: $listenappColor;
      height: 33px;
      width: 3px;
    }
    &:before {
      left: -25px;
      -webkit-transform: rotate(-30deg);
      transform: rotate(-30deg);
      
    }
    &:after {
      right: -16px;
      -webkit-transform: rotate(30deg);
      transform: rotate(30deg);
    }
  }
}