@charset "UTF-8";

#all #contents {
  width: 980px;
  margin: 0 auto;
  line-height: 1.8;
  color: #333;
  @include below($md) {
    width: 100%;
    padding-bottom: 0!important;
  }

  p {
    &.txt-alert, &.txt-dw {
      @include below($md) {
        margin: 0 20px 40px 20px;
      }
    }
    &.txt-alert {
      font-size: 1.4rem;
      margin-bottom: 40px;
      padding-left: 1em;
      text-indent: -1em;
    }

    &.txt-dw {
      font-size: 1.4rem;
      margin-bottom: 40px;
    }

    &.logo {
      text-align: center;
      padding-bottom: 40px;
      display: none;
      @include below($md) {
        display: block;
      }
    }
  }
}