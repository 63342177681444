@charset "UTF-8";

.how-to_box {
  .how-to_item {
    width: 33%;
    font-weight: 500;
    margin-bottom: 60px;
    line-height: 1;
    @include below($md) {
      width: 40%;
      margin: 0 auto 33px;
      text-align: center;
      // max-width: 350px;
    }
    @include below($sm) {
      width: 100%;
      max-width: 360px;
    }
    .step {
      font-size: 1.8rem;
      border-radius: 8px;
      border: 3px solid $listenappColor;
      padding: 1px 8px 3px 10px;
      width: 65px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      letter-spacing: 1px;
      @include below($md) {
        padding: 2px 12px 3px 15px;
      }
      >span {
        font-size: 3rem;
        line-height: 1;
        margin-left: 4px;
      }
    }
    >.title {
      font-size: 2.2rem;
      margin-bottom: 22px;
      @include below($md) {
        text-align: left;
      }
    }
    >.recommend {
      font-size: 1.8rem;
      color: $listenappColor;
      margin-bottom: 26px;
      @include below($md) {
        text-align: left;
        margin-bottom: 22px;
      }
    }
    >img {
      max-width: 270px;
      @include below($md) {
        margin-left: 0;
        max-width: 100%;
      }
    }
    &:last-of-type {
      align-self: baseline;
      margin-top: 100px;
      width: 36%;
      @include below($md) {
        width: 67%;
        max-width: none;
        text-align: left;
        line-height: 1.3;
        margin-top: 26px;
        min-width: 275px;
      }
    }
    >.addition {
      font-size: 1.6rem;
      color: #fff;
      background: $listenappColor;
      border-radius: 8px;
      padding: 12px 13px;
      margin-bottom: 20px;
      display: inline-block;
      @include below($md) {
        padding: 11px 12px;
      }
    }
  }
 
  &.-yellow {
    .how-to_item {
      width: 31%!important;
      margin-right: 30px;
      margin-bottom: 30px;
      margin-top: 0!important;
      @include below($md) {
        margin: 0 auto 30px;
        width: 100%!important;
        text-align: center!important;
      }
      .step {
        border-color: $wideangle5;
        >span {
          font-size: 3rem;
          line-height: 1;
          margin-left: 4px;
        }
      }
      &:last-of-type {
        margin-top: 0;
        margin-right: 0;
        @include below($md) {
          min-width: auto;
          // max-width: 350px;
          margin: auto;
        }
      }
      >.description {
        font-size: 1.4rem;
        height: 125px;
        line-height: 2;
        @include below($md) {
          text-align: left;
          font-size: 1.5rem;
          height: auto;
          margin-bottom: 32px;
        }
        >span {
          border-radius: 25px;
          background-color: $wideangle5;
          padding: 6px 14px;
          margin-right: 6px;
          margin-left: 1px;
          display: inline-block;
          font-size: 1.3rem;
          line-height: 1;
          @include below($md) {
            font-size: 1.4rem;
          }
        }
      }
    }
  }
}