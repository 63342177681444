@charset "UTF-8";

#app {

  .h2-01 {
    font-weight: normal;
    line-height: 1;
    @include below($md) {
      margin-bottom: 30px!important;
    }
  }
  
}