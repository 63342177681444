@charset "UTF-8";

#all #contents {

  .block-01 {
    background-color: #14559a;
    text-align: center;
    font-size: 2.4rem;
    font-weight: 700;
    padding: 20px;
    @include below($sm) {
      font-size: 1.9rem;
    }
    >.img {
      box-shadow: none;
    }
    p {
      &.box-01 {
        color: #d6fd32;
        margin-bottom: 20px;
      }

      &.box-02 {
        color: #d6ff3f;
        padding: 15px;
        background-color: #376ea9;
      }
    }

    .block-download-ttl {
      color: #FFF;
      text-align: center;
      font-size: 2rem;
      font-weight: 700;

      >p {
        padding: 5px 0;
        @include below($md) {
          font-size: 1.6rem;
        }
      }

      &.es {
        margin-bottom: 30px;
        p {
          background-color: #f18a45;
        }
      }

      &.ad {
        p {
          background-color: #00a0ea;
        }
      }
    }

    .block-download {
      display: flex;
      justify-content: space-around;
      padding: 20px 140px;
      margin: 0 auto;
      width: auto;
      @include below($md) {
        display: block;
        padding: 20px 0;
        width: auto;
        text-align: center;
      }

      span {
        line-height: 0;
        @include below($md) {
          display: block;
        }
        a:hover {
          opacity: 0.6;
        }

        &:nth-child(1) {
          margin-left: 30px;
          @include below($md) {
            margin-left: 0;
            margin-bottom: 20px;
          }
        }

        &:nth-child(2) {
          margin-right: 30px;
          @include below($md) {
            margin-right: 0;
          }
        }

        img {
          height: 80px;
          text-align: center;
          @include below($md) {
            height: 65px;
          }
        }
      }
    }

    .btm {
      font-size: 1.6rem;
      color: #FFF;
      padding-top: 20px;
    }

    // listenapp
    &.-magenta {
      background: $listenappColor;
      padding: 31px 20px 25px;
      @include below($md) {
        padding: 12px 22px 22px;
      }
      >.box-01 {
        color: #fff;
        @include below($md) {
          line-height: 2.4;
        }
        >span.ruby {
          position: relative;
          >span {
            position: absolute;
            font-size: 14px;
            display: inline;
            width: 100%;
            left: 0;
            top: -19px;
            letter-spacing: 2px;
            @include below($md) {
              font-size: 13px;
              left: -1px;
              top: -12px;
              letter-spacing: 2px;
              line-height: 1;
            }
          }
        }
      }
      .block-download-ttl {
        margin-bottom: 0;
        .block-download {
          padding: 0;
          width: 55%;
          justify-content: space-between;
          margin: 0 0 0 231px;
          @include below($md) {
            width: 100%;
            margin: auto;
          }
          >span {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }

    &.-yellow {
      background-color: $wideangle5;
      padding: 22px 0;
      @include below($md) {
        padding: 24px 16px;
      }
      >.box-01 {
        color: #000;
        margin-bottom: 0;
        @include below($md) {
          text-align: left;
          font-size: 2.5rem;
          line-height: 1.5;
        }
      }
    }
    &.-app {
      background: none;
      padding: 0;
      @include below($md) {
        margin-bottom: 0;
      }
      >.txt {
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        >h2 {
          font-weight: normal;
        }
        .block-02 {
          margin-bottom: 20px;
        }
      }
    }
  }
}