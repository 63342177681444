@charset "UTF-8";
@import url(http://fonts.googleapis.com/earlyaccess/notosansjp.css);
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

html {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 10px;
}

#contents.listenapp {
  font-family: "Noto Sans CJK JP", "Noto Sans", sans-serif;
}

@media only screen and (max-width: 980px) {
  #header, #footer {
    display: none;
  }
}

@media only screen and (max-width: 980px) {
  body#app {
    min-width: auto;
  }
}

@media only screen and (max-width: 980px) {
  .app-catalog {
    padding: 20px;
  }
}

@media only screen and (max-width: 980px) {
  .app-catalog #all #contents .wrap {
    width: 100%;
  }
}

.app-catalog #all #contents .wrap .h1-01 {
  font-size: 2.3em;
  margin-bottom: 24px !important;
}

@media only screen and (max-width: 980px) {
  .app-catalog #all #contents .wrap .ul-01 > li {
    float: none;
    width: 100%;
  }
}

#all #contents .block-01 {
  background-color: #14559a;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 700;
  padding: 20px;
}

@media only screen and (max-width: 720px) {
  #all #contents .block-01 {
    font-size: 1.9rem;
  }
}

#all #contents .block-01 > .img {
  box-shadow: none;
}

#all #contents .block-01 p.box-01 {
  color: #d6fd32;
  margin-bottom: 20px;
}

#all #contents .block-01 p.box-02 {
  color: #d6ff3f;
  padding: 15px;
  background-color: #376ea9;
}

#all #contents .block-01 .block-download-ttl {
  color: #FFF;
  text-align: center;
  font-size: 2rem;
  font-weight: 700;
}

#all #contents .block-01 .block-download-ttl > p {
  padding: 5px 0;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-01 .block-download-ttl > p {
    font-size: 1.6rem;
  }
}

#all #contents .block-01 .block-download-ttl.es {
  margin-bottom: 30px;
}

#all #contents .block-01 .block-download-ttl.es p {
  background-color: #f18a45;
}

#all #contents .block-01 .block-download-ttl.ad p {
  background-color: #00a0ea;
}

#all #contents .block-01 .block-download {
  display: flex;
  justify-content: space-around;
  padding: 20px 140px;
  margin: 0 auto;
  width: auto;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-01 .block-download {
    display: block;
    padding: 20px 0;
    width: auto;
    text-align: center;
  }
}

#all #contents .block-01 .block-download span {
  line-height: 0;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-01 .block-download span {
    display: block;
  }
}

#all #contents .block-01 .block-download span a:hover {
  opacity: 0.6;
}

#all #contents .block-01 .block-download span:nth-child(1) {
  margin-left: 30px;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-01 .block-download span:nth-child(1) {
    margin-left: 0;
    margin-bottom: 20px;
  }
}

#all #contents .block-01 .block-download span:nth-child(2) {
  margin-right: 30px;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-01 .block-download span:nth-child(2) {
    margin-right: 0;
  }
}

#all #contents .block-01 .block-download span img {
  height: 80px;
  text-align: center;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-01 .block-download span img {
    height: 65px;
  }
}

#all #contents .block-01 .btm {
  font-size: 1.6rem;
  color: #FFF;
  padding-top: 20px;
}

#all #contents .block-01.-magenta {
  background: #c90259;
  padding: 31px 20px 25px;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-01.-magenta {
    padding: 12px 22px 22px;
  }
}

#all #contents .block-01.-magenta > .box-01 {
  color: #fff;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-01.-magenta > .box-01 {
    line-height: 2.4;
  }
}

#all #contents .block-01.-magenta > .box-01 > span.ruby {
  position: relative;
}

#all #contents .block-01.-magenta > .box-01 > span.ruby > span {
  position: absolute;
  font-size: 14px;
  display: inline;
  width: 100%;
  left: 0;
  top: -19px;
  letter-spacing: 2px;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-01.-magenta > .box-01 > span.ruby > span {
    font-size: 13px;
    left: -1px;
    top: -12px;
    letter-spacing: 2px;
    line-height: 1;
  }
}

#all #contents .block-01.-magenta .block-download-ttl {
  margin-bottom: 0;
}

#all #contents .block-01.-magenta .block-download-ttl .block-download {
  padding: 0;
  width: 55%;
  justify-content: space-between;
  margin: 0 0 0 231px;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-01.-magenta .block-download-ttl .block-download {
    width: 100%;
    margin: auto;
  }
}

#all #contents .block-01.-magenta .block-download-ttl .block-download > span {
  margin-left: 0;
  margin-right: 0;
}

#all #contents .block-01.-yellow {
  background-color: #ffc107;
  padding: 22px 0;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-01.-yellow {
    padding: 24px 16px;
  }
}

#all #contents .block-01.-yellow > .box-01 {
  color: #000;
  margin-bottom: 0;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-01.-yellow > .box-01 {
    text-align: left;
    font-size: 2.5rem;
    line-height: 1.5;
  }
}

#all #contents .block-01.-app {
  background: none;
  padding: 0;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-01.-app {
    margin-bottom: 0;
  }
}

#all #contents .block-01.-app > .txt {
  font-size: 12px;
  font-weight: normal;
  text-align: left;
}

#all #contents .block-01.-app > .txt > h2 {
  font-weight: normal;
}

#all #contents .block-01.-app > .txt .block-02 {
  margin-bottom: 20px;
}

#all #contents .block-02 {
  margin-bottom: 54px;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-02 {
    margin-bottom: 50px;
  }
}

#all #contents .block-02 p.img {
  overflow: hidden;
  position: relative;
  height: 540px;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-02 p.img {
    height: auto;
    min-height: 270px;
  }
  #all #contents .block-02 p.img:before {
    content: "";
    display: block;
    padding-top: 55.1%;
    /* 高さを幅の75%に固定 */
  }
}

#all #contents .block-02 p.img img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-02 p.img img {
    height: 100%;
  }
}

#all #contents .block-02 p.txt {
  font-size: 1.6rem;
  padding: 20px 20px 0 20px;
}

#all #contents .block-02 p.txt > .note {
  font-size: 1.4rem;
  display: block;
}

#all #contents .block-02.fade > li > a {
  line-height: 1;
}

#all #contents .block-kino {
  margin-bottom: 60px;
}

#all #contents .block-kino p.chatch {
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 720px) {
  #all #contents .block-kino p.chatch {
    padding: 0 20px;
    text-align: left;
  }
}

#all #contents .block-kino p.chatch span {
  background-color: #d6ff3f;
  font-size: 2.4rem;
  padding: 15px;
  font-weight: 700;
  display: inline-block;
  line-height: 1;
}

@media only screen and (max-width: 720px) {
  #all #contents .block-kino p.chatch span {
    display: block;
    font-size: 1.6rem;
  }
}

#all #contents .block-kino p.txt-kino {
  font-size: 1.6rem;
  margin-bottom: 40px;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-kino p.txt-kino {
    margin: 0 20px 40px 20px;
  }
}

@media only screen and (max-width: 720px) {
  #all #contents .block-kino p.txt-kino {
    margin-bottom: 0;
  }
}

#all #contents .block-kino p.txt-kino span {
  color: #cc3333;
  font-weight: 700;
}

#all #contents .block-kino .box-kino {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

#all #contents .block-kino .box-kino .item-kino {
  box-sizing: border-box;
  width: 215px;
  margin-right: 35px;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-kino .box-kino .item-kino {
    width: 47%;
    padding: 0 40px 40px 40px;
    margin: 0;
  }
}

@media only screen and (max-width: 720px) {
  #all #contents .block-kino .box-kino .item-kino {
    width: 100%;
    padding: 20px;
    margin-bottom: 60px;
  }
}

#all #contents .block-kino .box-kino .item-kino h3 {
  background-color: #f5f5f5;
  font-size: 2rem;
  padding: 15px;
  border-left: 2px solid #ff0000;
  line-height: 1;
  margin-bottom: 20px;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-kino .box-kino .item-kino h3 {
    font-size: 2.4rem;
  }
}

@media only screen and (max-width: 720px) {
  #all #contents .block-kino .box-kino .item-kino h3 {
    font-size: 1.7rem;
  }
}

#all #contents .block-kino .box-kino .item-kino p.txt {
  font-size: 1.6rem;
  margin-bottom: 20px;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-kino .box-kino .item-kino p.txt {
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
}

#all #contents .block-kino .box-kino .item-kino p.txt img.icon {
  width: 16px;
}

#all #contents .block-kino .box-kino .item-kino p.capture {
  padding: 15px;
  background-color: #e6e6e6;
  position: relative;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-kino .box-kino .item-kino p.capture {
    padding: 20px;
    background-color: #e6e6e6;
  }
}

#all #contents .block-kino .box-kino .item-kino p.capture img {
  width: 100%;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-kino .box-kino .item-kino p.capture img {
    width: 100%;
  }
}

#all #contents .block-kino .box-kino .item-kino p.capture:before {
  content: "";
  background: url("/wordbox/app/img/arrow_02.png") left top no-repeat;
  background-size: 100%;
  width: 14px;
  height: 45px;
  position: absolute;
  right: -25px;
  bottom: 45%;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-kino .box-kino .item-kino p.capture:before {
    content: "";
    background: url("/wordbox/app/img/arrow_02.png") left top no-repeat;
    background-size: 100%;
    width: 27px;
    height: 54px;
    position: absolute;
    right: -66px;
  }
}

@media only screen and (max-width: 720px) {
  #all #contents .block-kino .box-kino .item-kino p.capture:before {
    background: url("/wordbox/app/img/arrow_01.png") left top no-repeat;
    background-size: 100%;
    width: 54px;
    height: 27px;
    right: 50%;
    margin-right: -27px;
    top: auto;
    bottom: -60px;
  }
}

#all #contents .block-kino .box-kino .item-kino:last-child p.capture:before {
  display: none;
}

#all #contents .block-kino .box-kino .item-kino:nth-child(3) h3 {
  width: 433px;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-kino .box-kino .item-kino:nth-child(3) h3 {
    width: auto;
  }
}

#all #contents .block-kino .box-kino .item-kino:nth-child(4) {
  margin-right: 0;
}

#all #contents .block-kino .box-kino .item-kino:nth-child(4) h3 {
  visibility: hidden;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-kino .box-kino .item-kino:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 720px) {
  #all #contents .block-kino .box-kino .item-kino:last-child h3 {
    display: none;
  }
}

#all #contents .block-set-01 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-set-01 {
    display: block;
  }
}

@media only screen and (max-width: 980px) {
  #all #contents .block-set-01 .block-kino-02, #all #contents .block-set-01 .block-kino-03 {
    width: auto;
  }
}

#all #contents .block-set-01 .block-kino-02 {
  width: 500px;
}

#all #contents .block-set-01 .block-kino-03 {
  width: 450px;
}

#all #contents .block-set-02 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-set-02 {
    display: block;
  }
}

#all #contents .block-set-02 .block-kino {
  width: 475px;
}

@media only screen and (max-width: 980px) {
  #all #contents .block-set-02 .block-kino {
    width: auto;
  }
}

@media only screen and (max-width: 980px) {
  #all #contents .block-set-02 .block-kino-05 {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 980px) {
  #all #contents .block-kino-01 .box-kino {
    justify-content: flex-start;
  }
}

#all #contents {
  width: 980px;
  margin: 0 auto;
  line-height: 1.8;
  color: #333;
}

@media only screen and (max-width: 980px) {
  #all #contents {
    width: 100%;
    padding-bottom: 0 !important;
  }
}

@media only screen and (max-width: 980px) {
  #all #contents p.txt-alert, #all #contents p.txt-dw {
    margin: 0 20px 40px 20px;
  }
}

#all #contents p.txt-alert {
  font-size: 1.4rem;
  margin-bottom: 40px;
  padding-left: 1em;
  text-indent: -1em;
}

#all #contents p.txt-dw {
  font-size: 1.4rem;
  margin-bottom: 40px;
}

#all #contents p.logo {
  text-align: center;
  padding-bottom: 40px;
  display: none;
}

@media only screen and (max-width: 980px) {
  #all #contents p.logo {
    display: block;
  }
}

#app .h1-01 {
  text-align: left;
  font-size: 2.5em;
  padding: 8px 0 15px 60px;
  font-weight: normal;
  line-height: 1;
}

@media only screen and (max-width: 980px) {
  #app .h1-01 {
    width: 100%;
  }
}

#app h1 {
  text-align: center;
  font-size: 3.8em;
  padding: 20px 10px;
  font-weight: 900;
}

@media only screen and (max-width: 980px) {
  #app h1 {
    font-size: 3.4rem;
  }
}

@media only screen and (max-width: 720px) {
  #app h1 {
    font-size: 2.6rem;
  }
}

#app h1 span {
  font-size: 2.8rem;
}

@media only screen and (max-width: 720px) {
  #app h1 span {
    font-size: 1.9rem;
  }
}

#app h1 .span-01 {
  border-radius: 10px;
  padding: 8px;
  margin-right: 4px;
  background-color: #fb644a;
  color: #FFF;
  font-weight: 700;
}

#app h1.-magenta {
  font-size: inherit;
  background: #f3f3f3;
  margin-top: 40px;
  padding: 26px 10px 17px;
}

@media only screen and (max-width: 720px) {
  #app h1.-magenta {
    padding: 17px 10px 12px;
  }
}

#app h1.-magenta > .span-01 {
  background: #c90259;
  font-size: 22px;
  padding: 5px 33px;
  border-radius: 5px;
  margin-right: 0;
}

@media only screen and (max-width: 720px) {
  #app h1.-magenta > .span-01 {
    font-size: 18px;
    padding: 4px 28px;
  }
}

#app h1.-magenta > .span-02 {
  display: block;
  font-size: 38px;
  margin-top: 22px;
  position: relative;
}

@media only screen and (max-width: 720px) {
  #app h1.-magenta > .span-02 {
    margin-top: 18px;
    font-size: 34px;
  }
}

#app h1.-magenta > .span-02 > span {
  font-size: 18px;
  position: absolute;
  margin: 0;
  left: 0;
  right: 0;
  top: -10px;
  letter-spacing: 3px;
}

@media only screen and (max-width: 720px) {
  #app h1.-magenta > .span-02 > span {
    top: -4px;
    line-height: 1;
    font-size: 15px;
    letter-spacing: 1px;
  }
}

#app h1.-yellow {
  background: #f3f3f3;
  padding: 23px 10px;
  font-size: 3.8rem;
  line-height: 1;
  margin-top: 40px;
}

@media only screen and (max-width: 720px) {
  #app h1.-yellow {
    padding: 22px 17px;
    text-align: left;
    line-height: 1.2;
  }
}

#app .h2-01 {
  font-weight: normal;
  line-height: 1;
}

@media only screen and (max-width: 980px) {
  #app .h2-01 {
    margin-bottom: 30px !important;
  }
}

.block-kino h2 {
  font-size: 3.8rem;
  vertical-align: middle;
  line-height: 1;
  text-align: center;
  margin-bottom: 25px;
}

@media only screen and (max-width: 720px) {
  .block-kino h2 {
    margin-bottom: 20px;
    font-size: 2.3rem;
    text-align: left;
    padding: 0 20px;
  }
}

.block-kino h2 span {
  font-size: 2.8rem;
  display: inline-block;
  width: 106px;
  height: 106px;
  box-sizing: border-box;
  vertical-align: middle;
  padding: 37px 0 0 0;
  text-align: center;
  background: url("/wordbox/app/img/circle.png") left top no-repeat;
  background-size: 100%;
  color: #FFF;
  margin-right: 10px;
}

@media only screen and (max-width: 720px) {
  .block-kino h2 span {
    font-size: 1.9rem;
    padding: 27px 0 0 0;
    width: 70px;
    height: 70px;
  }
}

.block-set-01 .block-kino > h2 {
  text-align: left;
}

@media only screen and (max-width: 980px) {
  .block-set-01 .block-kino > h2 {
    text-align: center;
  }
}

@media only screen and (max-width: 720px) {
  .block-set-01 .block-kino > h2 {
    text-align: left;
  }
}

.block-set-02 h2 {
  text-align: left;
}

@media only screen and (max-width: 980px) {
  .block-set-02 h2 {
    text-align: center;
  }
}

@media only screen and (max-width: 720px) {
  .block-set-02 h2 {
    text-align: left;
  }
}

.how-to_box {
  display: flex;
  flex-wrap: wrap;
  padding: 0 38px 0 25px;
}

@media only screen and (max-width: 980px) {
  .how-to_box {
    padding: 0 20px;
    display: block;
  }
}

.how-to_box.-yellow {
  align-items: flex-start;
}

.how-to_box .how-to_item {
  width: 33%;
  font-weight: 500;
  margin-bottom: 60px;
  line-height: 1;
}

@media only screen and (max-width: 980px) {
  .how-to_box .how-to_item {
    width: 40%;
    margin: 0 auto 33px;
    text-align: center;
  }
}

@media only screen and (max-width: 720px) {
  .how-to_box .how-to_item {
    width: 100%;
    max-width: 360px;
  }
}

.how-to_box .how-to_item .step {
  font-size: 1.8rem;
  border-radius: 8px;
  border: 3px solid #c90259;
  padding: 1px 8px 3px 10px;
  width: 65px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  letter-spacing: 1px;
}

@media only screen and (max-width: 980px) {
  .how-to_box .how-to_item .step {
    padding: 2px 12px 3px 15px;
  }
}

.how-to_box .how-to_item .step > span {
  font-size: 3rem;
  line-height: 1;
  margin-left: 4px;
}

.how-to_box .how-to_item > .title {
  font-size: 2.2rem;
  margin-bottom: 22px;
}

@media only screen and (max-width: 980px) {
  .how-to_box .how-to_item > .title {
    text-align: left;
  }
}

.how-to_box .how-to_item > .recommend {
  font-size: 1.8rem;
  color: #c90259;
  margin-bottom: 26px;
}

@media only screen and (max-width: 980px) {
  .how-to_box .how-to_item > .recommend {
    text-align: left;
    margin-bottom: 22px;
  }
}

.how-to_box .how-to_item > img {
  max-width: 270px;
}

@media only screen and (max-width: 980px) {
  .how-to_box .how-to_item > img {
    margin-left: 0;
    max-width: 100%;
  }
}

.how-to_box .how-to_item:last-of-type {
  align-self: baseline;
  margin-top: 100px;
  width: 36%;
}

@media only screen and (max-width: 980px) {
  .how-to_box .how-to_item:last-of-type {
    width: 67%;
    max-width: none;
    text-align: left;
    line-height: 1.3;
    margin-top: 26px;
    min-width: 275px;
  }
}

.how-to_box .how-to_item > .addition {
  font-size: 1.6rem;
  color: #fff;
  background: #c90259;
  border-radius: 8px;
  padding: 12px 13px;
  margin-bottom: 20px;
  display: inline-block;
}

@media only screen and (max-width: 980px) {
  .how-to_box .how-to_item > .addition {
    padding: 11px 12px;
  }
}

.how-to_box.-yellow .how-to_item {
  width: 31% !important;
  margin-right: 30px;
  margin-bottom: 30px;
  margin-top: 0 !important;
}

@media only screen and (max-width: 980px) {
  .how-to_box.-yellow .how-to_item {
    margin: 0 auto 30px;
    width: 100% !important;
    text-align: center !important;
  }
}

.how-to_box.-yellow .how-to_item .step {
  border-color: #ffc107;
}

.how-to_box.-yellow .how-to_item .step > span {
  font-size: 3rem;
  line-height: 1;
  margin-left: 4px;
}

.how-to_box.-yellow .how-to_item:last-of-type {
  margin-top: 0;
  margin-right: 0;
}

@media only screen and (max-width: 980px) {
  .how-to_box.-yellow .how-to_item:last-of-type {
    min-width: auto;
    margin: auto;
  }
}

.how-to_box.-yellow .how-to_item > .description {
  font-size: 1.4rem;
  height: 125px;
  line-height: 2;
}

@media only screen and (max-width: 980px) {
  .how-to_box.-yellow .how-to_item > .description {
    text-align: left;
    font-size: 1.5rem;
    height: auto;
    margin-bottom: 32px;
  }
}

.how-to_box.-yellow .how-to_item > .description > span {
  border-radius: 25px;
  background-color: #ffc107;
  padding: 6px 14px;
  margin-right: 6px;
  margin-left: 1px;
  display: inline-block;
  font-size: 1.3rem;
  line-height: 1;
}

@media only screen and (max-width: 980px) {
  .how-to_box.-yellow .how-to_item > .description > span {
    font-size: 1.4rem;
  }
}

.how-to-hdg {
  text-align: center;
  margin-bottom: 50px;
  line-height: 1;
}

.how-to-hdg > span {
  font-size: 2.8rem;
  text-align: center;
  font-weight: 700;
  position: relative;
}

@media only screen and (max-width: 720px) {
  .how-to-hdg > span {
    font-size: 2.5rem;
  }
}

.how-to-hdg > span:before, .how-to-hdg > span:after {
  content: "";
  position: absolute;
  display: inline-block;
  top: 6px;
  background-color: #c90259;
  height: 33px;
  width: 3px;
}

.how-to-hdg > span:before {
  left: -25px;
  -webkit-transform: rotate(-30deg);
  transform: rotate(-30deg);
}

.how-to-hdg > span:after {
  right: -16px;
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.books-list {
  margin-top: 46px;
  margin-bottom: 20px;
  font-size: 1.4rem;
}

@media only screen and (max-width: 720px) {
  .books-list {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}

.books-list > p {
  margin-bottom: 30px;
}

@media only screen and (max-width: 720px) {
  .books-list > p {
    margin-bottom: 20px;
  }
}

.listenapp .function-list {
  list-style: none;
  counter-reset: number;
  padding: 0;
  margin-top: 18px;
}

.listenapp .function-list li {
  font-size: 1.4rem;
  line-height: normal;
  margin-bottom: 0.4em;
}

.listenapp .function-list li .number {
  counter-increment: number;
  content: counter(number);
  background: #fff;
  border: 1px solid #c90259;
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  border-radius: 50%;
  color: #c90259;
  margin-right: 6px;
  font-weight: 900;
  font-size: 13px;
}

.listenapp .function-list li .number.-for {
  margin-right: 2px;
}

.listenapp .function-list li > .title {
  font-size: 1.6rem;
  font-weight: 900;
  display: inline-block;
}

.listenapp .function-list li > .description {
  padding-left: 18px;
  margin-top: 12px;
  margin-bottom: 14px;
}

.listenapp .how-to_box .how-to_item {
  width: 100%;
  margin-top: 50px;
}

@media only screen and (max-width: 720px) {
  .listenapp .how-to_box .how-to_item {
    max-width: none;
    text-align: left;
  }
}

.listenapp .how-to_box .how-to_item:first-of-type {
  margin-top: 0;
}

.listenapp .how-to_box .how-to_item:last-of-type {
  margin-bottom: 0;
}

.listenapp .how-to_box .how-to_item > .title {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
}

@media only screen and (max-width: 720px) {
  .listenapp .how-to_box .how-to_item > .title {
    margin-bottom: 20px;
  }
}

.listenapp .how-to_box .how-to_item > .title .step {
  margin-bottom: 0;
  margin-right: 18px;
}

.listenapp .how-to_box .how-to_item > .title .step.function {
  background: #c90259;
  color: #fff;
  font-size: 16px;
  width: auto;
  padding: 10px 12px;
}

.listenapp .how-to_box .how-to_item > p {
  font-size: 1.6rem;
  font-weight: normal;
  margin-bottom: 36px;
  line-height: normal;
}

@media only screen and (max-width: 720px) {
  .listenapp .how-to_box .how-to_item > p {
    margin-bottom: 28px;
  }
}

.listenapp .how-to_box .how-to_item > .image-box {
  width: 70%;
  margin: auto;
  display: flex;
}

@media only screen and (max-width: 720px) {
  .listenapp .how-to_box .how-to_item > .image-box {
    width: 100%;
    display: block;
    text-align: center;
  }
}

.listenapp .how-to_box .how-to_item > .image-box > img:first-of-type {
  margin-right: 96px;
}

@media only screen and (max-width: 720px) {
  .listenapp .how-to_box .how-to_item > .image-box > img:first-of-type {
    margin-right: auto;
  }
}

@media only screen and (max-width: 720px) {
  .listenapp .how-to_box .how-to_item > .image-box > img {
    margin: 0 auto 40px !important;
  }
}

.listenapp .how-to_box .how-to_item > .image-box.function > img {
  margin-right: 0;
}

@media only screen and (max-width: 720px) {
  .listenapp .how-to_box .how-to_item > .image-box.function > img {
    width: 100%;
  }
}

@media only screen and (max-width: 980px) {
  body#app .none {
    display: none;
  }
}

body#app .none-02 {
  display: none;
}

@media only screen and (max-width: 720px) {
  body#app .none-02 {
    display: block;
  }
}

@media only screen and (max-width: 980px) {
  #all #contents .note {
    padding: 0 12px;
    margin-bottom: 30px;
    margin-top: 20px;
  }
}

p.dekirukoto {
  background: #fb644a;
  font-size: 2.8rem;
  color: #FFF;
  text-align: center;
  padding: 30px 0;
  margin-bottom: 50px;
}

@media only screen and (max-width: 980px) {
  p.dekirukoto {
    margin: 0 20px 40px 20px;
  }
}

@media only screen and (max-width: 720px) {
  p.dekirukoto {
    font-size: 1.9rem;
    font-weight: 700;
  }
}

@media only screen and (max-width: 980px) {
  #app .pc {
    display: none;
  }
}

#app .sp {
  display: none;
}

@media only screen and (max-width: 980px) {
  #app .sp {
    display: block;
  }
}

#all #contents .schedule {
  line-height: 1;
  margin-top: 23px;
  color: #e52827;
  font-weight: 600;
  text-align: center;
  font-size: 24px;
  margin-bottom: 28px;
}

@media only screen and (max-width: 720px) {
  #all #contents .schedule {
    margin: 20px 0;
  }
}

@media only screen and (max-width: 980px) {
  #app #topicpass {
    display: none !important;
  }
}
