@charset "UTF-8";

#app {

  .h1-01 {
    text-align: left;
    font-size: 2.5em;
    padding: 8px 0 15px 60px;
    font-weight: normal;
    line-height: 1;
    @include below($md) {
      width: 100%;
    }
  }
  
}