@charset "UTF-8";

#app {

  #topicpass {
    @include below($md) {
      display: none!important;
    }
  }
  
}