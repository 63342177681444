@charset "UTF-8";

#all #contents {

  .block-02 {
    margin-bottom: 54px;
    @include below($md) {
      margin-bottom: 50px;
    }
    
    p {
      &.img {
        overflow: hidden;
        position: relative;
        height: 540px;
        @include below($md) {
          height: auto;
          min-height: 270px;
          &:before {
            content: "";
            display: block;
            padding-top: 55.1%;
            /* 高さを幅の75%に固定 */
          }
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: auto;
          @include below($md) {
            height: 100%;
          }
        }
      }

      &.txt {
        font-size: 1.6rem;
        padding: 20px 20px 0 20px;
        // @include below($md) {
        //   padding: 20px 20px;
        // }
        >.note {
          font-size: 1.4rem;
          display: block;
        }
      }
    }
    &.fade {
      >li {
        >a {
          line-height: 1;
        }
      }
    }
  }
}