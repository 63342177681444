@charset "UTF-8";

#all #contents {
  .note {
    @include below($md) {
      padding: 0 12px;
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }
}