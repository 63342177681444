@charset "UTF-8";

.how-to_box {
  display: flex;
  flex-wrap: wrap;
  // align-items: flex-end;
  padding: 0 38px 0 25px;
  @include below($md) {
    padding: 0 20px;
    display: block;
  }
  &.-yellow {
    align-items: flex-start;
  }
}