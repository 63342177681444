@charset "UTF-8";

#app {

  .pc {
    @include below($md) {
      display: none;
    }
  }
  .sp {
    display: none;
    @include below($md) {
      display: block;
    }
  }
}