@charset "UTF-8";

#all #contents {
.schedule {
    line-height: 1;
    margin-top: 23px;
    color: #e52827;
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    margin-bottom: 28px;
    @include below($sm) {
      margin: 20px 0;
    }
  }
}