@charset "UTF-8";

.app-catalog {
  @include below($md) {
    padding: 20px;
  }
  #all {
    #contents {
      .wrap {
        @include below($md) {
          // padding: 20px;
          width: 100%;
        }
        .h1-01 {
          font-size: 2.3em;
          margin-bottom: 24px!important;
        }
        .ul-01 {
          >li {
            @include below($md) {
              float: none;
              width: 100%;
            }
          }
        }
      }
    }
  }
}