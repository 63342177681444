@charset "UTF-8";

#app {

  h1 {
    text-align: center;
    font-size: 3.8em;
    padding: 20px 10px;
    font-weight: 900;
    @include below($md) {
      font-size: 3.4rem;
    }
    @include below($sm) {
      font-size: 2.6rem;
    }

    span {
      font-size: 2.8rem;
      @include below($sm) {
        font-size: 1.9rem;
      }
    }

    .span-01 {
      border-radius: 10px;
      padding: 8px;
      margin-right: 4px;
      background-color: #fb644a;
      color: #FFF;
      font-weight: 700;
    }

    // listenapp
    &.-magenta {
      font-size: inherit;
      background: #f3f3f3;
      margin-top: 40px;
      padding: 26px 10px 17px;
      @include below($sm) {
        padding: 17px 10px 12px;
      }
      
      >.span-01 {
        background: $listenappColor;
        font-size: 22px;
        padding: 5px 33px;
        border-radius: 5px;
        margin-right: 0;
        @include below($sm) {
          font-size: 18px;
          padding: 4px 28px;
        }
      }
      >.span-02 {
        display: block;
        font-size: 38px;
        margin-top: 22px;
        position: relative;
        @include below($sm) {
          // font-size: 22px;
          margin-top: 18px;
          font-size: 34px;
        }
        >span {
          font-size: 18px;
          position: absolute;
          margin: 0;
          left: 0;
          right: 0;
          top: -10px;
          letter-spacing: 3px;
          @include below($sm) {
            top: -4px;
            line-height: 1;
            font-size: 15px;
            letter-spacing: 1px;
          }
        }
      }
    }

    &.-yellow {
      background: #f3f3f3;
      padding: 23px 10px;
      font-size: 3.8rem;
      line-height: 1;
      margin-top: 40px;
      @include below($sm) {
        padding: 22px 17px;
        text-align: left;
        line-height: 1.2;
      }
    }
  }
}