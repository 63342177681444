@charset "UTF-8";

.block-kino {

  h2 {
    font-size: 3.8rem;
    vertical-align: middle;
    line-height: 1;
    text-align: center;
    margin-bottom: 25px;
    @include below($sm) {
      margin-bottom: 20px;
      font-size: 2.3rem;
      text-align: left;
      padding: 0 20px;
    }
    span {
      font-size: 2.8rem;
      display: inline-block;
      width: 106px;
      height: 106px;
      box-sizing: border-box;
      vertical-align: middle;
      padding: 37px 0 0 0;
      text-align: center;
      background: url("/wordbox/app/img/circle.png") left top no-repeat;
      background-size: 100%;
      color: #FFF;
      margin-right: 10px;
      @include below($sm) {
        font-size: 1.9rem;
        padding: 27px 0 0 0;
        width: 70px;
        height: 70px;
      }
    }
  }
}

.block-set-01 {
  .block-kino {
    >h2 {
      text-align: left;
      @include below($md) {
        text-align: center;
      }
      @include below($sm) {
        text-align: left;
      }
    }
  }
}

.block-set-02 {
  h2 {
    text-align: left;
    @include below($md) {
      text-align: center;
    }
    @include below($sm) {
      text-align: left;
    }
  }
}