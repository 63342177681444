@charset "UTF-8";

.listenapp {
  .how-to_box {
    .how-to_item {
      width: 100%;
      margin-top: 50px;
      @include below($sm) {
        max-width: none;
        text-align: left;
      }

      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      >.title {
        display: flex;
        align-items: center;
        margin-bottom: 28px;
        @include below($sm) {
          margin-bottom: 20px;
        }
        .step {
          margin-bottom: 0;
          margin-right: 18px;
          &.function {
            background: $listenappColor;
            color: #fff;
            font-size: 16px;
            width: auto;
            padding: 10px 12px;
          }
        }
      }
      
      >p {
        font-size: 1.6rem;
        font-weight: normal;
        margin-bottom: 36px;
        line-height: normal;
        @include below($sm) {
          margin-bottom: 28px;
        }
      }
      
      >.image-box {
        width: 70%;
        margin: auto;
        display: flex;
        @include below($sm) {
          width: 100%;
          display: block;
          text-align: center;
        }

        >img {
          &:first-of-type {
            margin-right: 96px;
            @include below($sm) {
              margin-right: auto;
            }
          }
          @include below($sm) {
            margin: 0 auto 40px!important;
          }
        }

        &.function {
          >img {
            margin-right: 0;
            @include below($sm) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
