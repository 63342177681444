@charset "UTF-8";
p.dekirukoto {
  background: #fb644a;
  font-size: 2.8rem;
  color: #FFF;
  text-align: center;
  padding: 30px 0;
  margin-bottom: 50px;
  @include below($md) {
    margin: 0 20px 40px 20px;
  }
  @include below($sm) {
    font-size: 1.9rem;
    font-weight: 700;
  }
}