@charset "UTF-8";

@import url(http://fonts.googleapis.com/earlyaccess/notosansjp.css);

@keyframes show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


html {
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 10px;
}

#contents.listenapp {
  font-family: "Noto Sans CJK JP", "Noto Sans", sans-serif;
}