@charset "UTF-8";

#all #contents {

  .block-kino {
    margin-bottom: 60px;

    p {
      &.chatch {
        text-align: center;
        margin-bottom: 20px;
        @include below($sm) {
          padding: 0 20px;
          text-align: left;
        }

        span {
          background-color: #d6ff3f;
          font-size: 2.4rem;
          padding: 15px;
          font-weight: 700;
          display: inline-block;
          line-height: 1;
          @include below($sm) {
            display: block;
            font-size: 1.6rem;
          }
        }
      }

      &.txt-kino {
        font-size: 1.6rem;
        margin-bottom: 40px;
        @include below($md) {
          margin: 0 20px 40px 20px;
        }
        @include below($sm) {
          margin-bottom: 0;
        }

        span {
          color: #cc3333;
          font-weight: 700;
        }
      }
    }

    .box-kino {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      // @include below($md) {
      //   justify-content: flex-start;
      // }

      .item-kino {
        box-sizing: border-box;
        width: 215px;
        margin-right: 35px;
        @include below($md) {
          width: 47%;
          padding: 0 40px 40px 40px;
          margin: 0;
        }
        @include below($sm) {
          width: 100%;
          padding: 20px;
          margin-bottom: 60px;
        }

        h3 {
          background-color: #f5f5f5;
          font-size: 2rem;
          padding: 15px;
          border-left: 2px solid #ff0000;
          line-height: 1;
          margin-bottom: 20px;
          @include below($md) {
            font-size: 2.4rem;
          }
          @include below($sm) {
            font-size: 1.7rem;
          }
        }

        p {
          &.txt {
            font-size: 1.6rem;
            margin-bottom: 20px;
            @include below($md) {
              font-size: 1.6rem;
              margin-bottom: 20px;
            }

            img.icon {
              width: 16px;
            }
          }

          &.capture {
            padding: 15px;
            background-color: #e6e6e6;
            position: relative;
            @include below($md) {
              padding: 20px;
              background-color: #e6e6e6;
            }

            img {
              width: 100%;
              @include below($md) {
                width: 100%;
              }
            }

            &:before {
              content: "";
              background: url("/wordbox/app/img/arrow_02.png") left top no-repeat;
              background-size: 100%;
              width: 14px;
              height: 45px;
              position: absolute;
              right: -25px;
              bottom: 45%;
              @include below($md) {
                content: "";
                background: url("/wordbox/app/img/arrow_02.png") left top no-repeat;
                background-size: 100%;
                width: 27px;
                height: 54px;
                position: absolute;
                right: -66px;
              }
              @include below($sm) {
                background: url("/wordbox/app/img/arrow_01.png") left top no-repeat;
                background-size: 100%;
                width: 54px;
                height: 27px;
                right: 50%;
                margin-right: -27px;
                top: auto;
                bottom: -60px;
              }
            }
          }
        }

        &:last-child p.capture:before {
          display: none;
        }

        &:nth-child(3) h3 {
          width: 433px;
          @include below($md) {
            width: auto;
          }
        }

        &:nth-child(4) {
          margin-right: 0;

          h3 {
            visibility: hidden;
          }
        }
        &:last-child {
          @include below($md) {
            margin-bottom: 0;
          }
          h3 {
            @include below($sm) {
              display: none;
            }
          }
        }
      }
    }
  }
  .block-set-01 {
    display: flex;
    justify-content: space-between;
    @include below($md) {
      display: block;
    }
    .block-kino-02, .block-kino-03 {
      @include below($md) {
        width: auto;
      }
    }
    .block-kino-02 {
      width: 500px;

    }
    .block-kino-03 {
      width: 450px;
    }
  }

  .block-set-02 {
    display: flex;
    justify-content: space-between;
    @include below($md) {
      display: block;
    }
    .block-kino {
      width: 475px;
      @include below($md) {
        width: auto;
      }
    }
    .block-kino-05 {
      @include below($md) {
        margin-bottom: 0;
      }
    }
  }
  .block-kino-01 {
    .box-kino {
      @include below($md) {
        justify-content: flex-start;
      }
    }
  }
}